import { CurrentUserContext } from "@/contexts/currentUserContext";
import { ISession } from "@/services/auth.server";
import { READONLY_PROVIDER } from "@/utils/constants";
import { isNil } from "lodash-es";
import { useContext } from "react";

export interface ICondoitSession {
  session: ISession | null;
  authStatus: string;
  id_token: string;
}

function sessionStatus(expires_at: number | undefined): "authenticated" | "unauthenticated" {
  try {
    let valid = true;
    if (isNil(expires_at)) return "unauthenticated";
    valid = expires_at > Date.now() / 1000;
    return valid ? "authenticated" : "unauthenticated";
  } catch (error) {
    return "unauthenticated";
  }
}

export function useAuthenticator() {
  //This would be the preferred setup so we can have unauthenticated routes
  // const session = useSession({ required: true });
  const currentUserContext = useContext(CurrentUserContext);
  const status = sessionStatus(currentUserContext?.session?.expires_at);
  const session = currentUserContext?.session;
  return {
    session: session,
    authStatus: status,
    authenticated: status === "authenticated",
    expired: status === "unauthenticated",
    id_token: session?.access_token,
    readonly: session?.id === READONLY_PROVIDER,
    qrToken: session?.id_token ?? false,
  };
}
